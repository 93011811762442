// src/App.js
import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter as Router, Route, Routes, useNavigate, Link} from 'react-router-dom';
import ParticlesBackground from './ParticlesBackground';
import Home from './Home';
import Profile from './pages/Profile';
import './App.css';
import { Button } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FFFFFF',
    },
    secondary: {
      main: '#F4B22E',
    },
  },
  typography: {
    h1: {
      fontSize: '4rem',
      '@media (max-width:600px)': {
        fontSize: '2rem',
      },
      '@media (min-width:600px)': {
        fontSize: '3rem',
      },
    },
    body1: {
      fontSize: '1.5rem',
      '@media (max-width:600px)': {
        fontSize: '1rem',
      },
      '@media (min-width:600px)': {
        fontSize: '1.2rem',
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
    <Router>
      <Link to="/">
        <div className="header-text">zukaseka</div>
      </Link>
      <div className="App">
        <ParticlesBackground />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/profile" element={<Profile />} />
        </Routes>
        <BottomButton />
      </div>
    </Router>
  </ThemeProvider>
  );
}

const BottomButton = () => {
  const navigate = useNavigate();
  return (
    <div style={{ position: 'relative', textAlign: 'center'}}>
      <Button 
        variant="contained" 
        color="primary" 
        onClick={() => {navigate('/');}}
        style={{
          backgroundColor: '#F4B22E',
          fontWeight: 'bold',
          borderRadius: '20px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
          margin: '30px 0' }}
      >
        トップページに戻る
      </Button>
    </div>
  );
};
export default App;
