import React, { useEffect,useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, CircularProgress } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Profile = () => {
  const [expanded, setExpanded] = useState([]);
  const [mangaList, setMangaList] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleChange = (panel) => (event) => {
    setExpanded((prev) => {
      if (prev.includes(panel)) {
        return prev.filter((p) => p !== panel);
      } else {
        return [...prev, panel];
      }
    });
  };

  const accordionData = [
    {
      title: "経歴",
      content: [
        "大学時代は単位取得、サークル、麻雀に没頭。なんとかギリギリ卒業。",
        "日本で一番有名な大学院に転籍。モラトリアムを謳歌しながら研究三昧。",
        "人との出会いから、研究の道を捨ててIT業界に挑戦。環境を変えがちである。",
        "現在は大手SIerで働きながら、ITキャリア支援をしている。毎日が楽しい。",
      ],
    },
    {
      title: "スキルセット",
      content: [
        "ソフトウェア開発における品質基準の設定と評価",
        "品質保証プロセスの監視と改善",
        "故障管理、品質リスクの識別と対応策の策定",
        "C#、Java、JavaScriptによる開発経験",
      ],
    },
    {
      title: "保有資格",
      content: [
        "応用情報技術者",
        "基本情報技術者",
        "Certified Project Management Associate D",
        "Oracle Certified Java Programmer, Silver SE 11",
        "中学校教諭一種免許状(理科)",
        "高等学校教諭一種免許状(理科)",
        "普通自動車第一種運転免許（AT限定）",
      ],
    },
    {
      title: "趣味",
      content: [
        "読書。最近はもっぱら実践的な本しか読まない。オススメ本教えて。",
        "麻雀。学生時代に麻雀が好きすぎて、近代麻雀という雑誌で記事を書いていた。",
        "映画鑑賞。基本的に洋画しか見ない。Disney+とAmazomプライムのヘビーユーザー。",
        "ポーカー。テキサスホールデム。後輩を都内のアミューズメントカジノに誘うことばかりしている。",
        "ラケット競技。硬式テニス、バドミントン、卓球は人より得意。近頃はいずれも1時間でバテる...。"
      ],
    },
    {
      title: "エンジニアの失敗談",
      content: [
        "新人時代に任された進捗管理タスクの期限を過ぎてしまったこと。チームメンバーの前で激詰めされた。ミイラ取りがミイラに。",
      ],
    },
    {
      title: "エンジニアの成功談",
      content: [
        "私が携わった大規模プロジェクトが一年遅延しながらも無事リリース。退プロ時に偉い方がたくさん褒めてくれた。",
      ],
    },
    {
      title: "幸せを感じる瞬間",
      content: [
        "作り上げたサービスが世に出たとき。個人開発、大規模プロジェクト、リアルなモノ作り、いずれも同じ感情である。",
      ],
    },
    {
      title: "最近の悩み",
      content: [
        "肩凝り。テレワークの弊害である。さらに中年太り。エンジニアになってから3年間で10kg太った。健康の悩みを挙げ始めたらキリがない。",
      ],
    },
    {
      title: "座右の銘",
      content: [
        "コツコツは勝つコツ",
      ],
    },
    {
      title: "口癖",
      content: [
        "「エンジニアってチート職業っす！」",
      ],
    },
  ];
  const fetchMangaData = async () => {
    const mangaTitles = [
      "Dragon Ball",
      "Hagane no Renkinjutsushi, FMA, Full Metal Alchemist",
      "ΠΛΑΝΗΤΕΣ",
      "My Hero Academia",
      "Claymore",
      "Karakuri Circus",
    ];
  const mangaData = await Promise.all(mangaTitles.map(async (title) => {
    let attempts = 0;
    const maxAttempts = 6;
    
    while (attempts < maxAttempts) {
      try {
        const response = await fetch(`https://api.jikan.moe/v4/manga?q=${encodeURIComponent(title)}`);
        if (!response.ok) {
          if (response.status === 429) {
            attempts++;
            const waitTime = 1000 * attempts;
            await new Promise(resolve => setTimeout(resolve, waitTime));
            continue;
          }
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        if (data.data && data.data.length > 0) {
          return data.data[0];
        } else {
          return null;
        }
      } catch (error) {
        console.error(`Error fetching manga data for ${title}:`, error);
        return null;
      }
    }
    return null;
  }));
  return mangaData.filter(manga => manga !== null);
};
useEffect(() => {
  const loadMangaData = async () => {
    setLoading(true);
    const mangaData = await fetchMangaData();
    setMangaList(mangaData);
    setLoading(false);
  };
  loadMangaData();
}, []);
  return (
    <div>
      <div className="title-container">
        <h1 className="overlay-text"><br/>Profile</h1>
        <h2 className="title">真面目なプロフィール</h2>
      </div>
      <div className="image-container">
        <img src={require('../images/Azami.png')} alt="アザミ" className="azami" />
        
        <img 
          src={require('../images/React.png')} 
          alt="React" 
          className="react-image third"
        />
        <img 
          src={require('../images/React.png')} 
          alt="React" 
          className="react-image fourth" 
        />
        <div className="scroll-indicator"></div>
      </div>
      {accordionData.map((item, index) => (
        <Accordion 
          key={index} 
          expanded={expanded.includes(`panel${index}`)} 
          onChange={handleChange(`panel${index}`)} 
          style={{ margin: '0 5%' }}
          className="accordion-container"
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>{item.title}</Typography>
          </AccordionSummary>
          <hr style={{ 
            margin: '0 0 10px 0', 
            border: '0.5px solid rgba(0, 0, 0, 0.2)', 
            width: '90%',
            marginLeft: '5%'
          }} />
          <AccordionDetails>
            <div style={{ paddingLeft: '20px', textAlign: 'left' }}>
              <ul style={{ listStyleType: 'none', paddingLeft: '0', marginLeft: '0' }}>
                {item.content.map((text, idx) => (
                  <li key={idx} style={{ position: 'relative', paddingLeft: '20px', marginBottom: '10px' }}>
                    <span style={{
                      position: 'absolute',
                      left: '0',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      width: '15px',
                      height: '0.5px',
                      backgroundColor: '#1D2D4A'
                    }} />
                    <Typography variant="body2" style={{ fontSize: '0.875rem' }}>
                      {text}
                    </Typography>
                  </li>
                ))}
              </ul>
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
      <div className="title-container">
        <h1 className="overlay-text"><br/>Comics</h1>
        <h2 className="title">好きな漫画たち</h2>
      </div>
      <div className="manga-carousel">
      {loading ? (
          <CircularProgress />
        ) : (
          mangaList.length > 0 ? (
            <>
              {mangaList.map((manga) => (
                <img key={manga.mal_id} src={manga.images.jpg.image_url} alt={manga.title} className="manga-image" />
              ))}
              {mangaList.map((manga) => (
                <img key={manga.mal_id + '-copy'} src={manga.images.jpg.image_url} alt={manga.title} className="manga-image" />
              ))}
            </>
          ) : (
            <Typography variant="body1">No manga found.</Typography>
          )
        )}
      </div>
    </div>
  );
};
export default Profile;