import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardMedia, Typography, Grid, IconButton } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LaunchIcon from '@mui/icons-material/Launch';
import './App.css';
import GitHubIcon from './images/Github.png';

const cardData = [
  {
    title: "自己紹介",
    description: "地球に爆誕してからの28年間で会得したものをまとめた。現在はエンジニア兼ITキャリア支援会社代表。",
    imageUrl: require('./images/ProfileImage.jpg'),
    link: "/profile",
    transitionFlag: false
  },
  {
    title: "ITクイズクエスト",
    description: "ゲーム感覚で楽しくIT基礎知識が学べるクイズアプリ。未経験からエンジニアを目指す方向け。有料教育アプリ10位を記録した。",
    imageUrl: require('./images/App.png'),
    link: "https://apps.apple.com/jp/app/it%E3%82%AF%E3%82%A4%E3%82%BA%E3%82%AF%E3%82%A8%E3%82%B9%E3%83%88/id6479227780",
    transitionFlag: true
  },
  {
    title: "ChatGPTパーフェクトガイド",
    description: "GPT3時代に作ったプレゼント資料。我ながら、今見返してもなかなかイケてる。転載禁止。",
    imageUrl: require('./images/ChatGPT.png'),
    link: "https://www.canva.com/design/DAFbgIiQlm4/2XVKBeOWYqw3W8zGCCMXRA/edit?utm_content=DAFbgIiQlm4&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton",
    transitionFlag: true
  },
  {
    title: "ずかせかInstagram",
    description: "IT情報発信中。2024年にフォロワー5,000人突破。フォローしてくれるとめっっっっっちゃ嬉しい。小さい「っ」が5つ入るくらい。",
    imageUrl: require('./images/Instagram.jpg'),
    link: "https://www.instagram.com/it_zukai_sekai/",
    transitionFlag: true
  },
  {
    title: "合同会社STREAMLINE",
    description: "私が代表を務めている会社のHP。ITキャリア支援事業を行っているので、迷えるエンジニアは気軽に連絡して。未経験の方もOK。",
    imageUrl: require('./images/Streamline.png'),
    link: "https://streamline0213.co.jp/",
    transitionFlag: true
  },
  {
    title: "合同会社Bremch",
    description: "私が特別顧問を務めている会社のHP。クラウド、オンプレ問わずインフラ領域が強み。インフラエンジニアを目指している人はぜひ。",
    imageUrl: require('./images/Bremch.png'),
    link: "https://bremch.co.jp/",
    transitionFlag: true
  },
  {
    title: "???",
    description: "Coming Soon...",
    imageUrl: require('./images/TreasureChest.png'),
    link: "/",
    transitionFlag: false
  },
];

const Home = () => {
  const [visibleCards, setVisibleCards] = useState(Array(cardData.length).fill(false));
  const cardRefs = useRef([]);
  const navigate = useNavigate();

  useEffect(() => {
    setVisibleCards(Array(cardData.length).fill(false));
  }, []);

  return (
    <>
      <div className="title-container">
        <h1 className="overlay-text">Engineering &<br/>Career support</h1>
        <h2 className="title">エンジニア仲間を増やす</h2>
      </div>
      <div className="image-container">
        <img src={require('./images/Monalisa.png')} alt="モナリザ" className="monalisa" />
        
        <img
          src={require('./images/React.png')} 
          alt="React" 
          className="react-image" 
        />
        <img 
          src={require('./images/React.png')} 
          alt="React" 
          className="react-image second" 
        />
        <div className="scroll-indicator"></div>
      </div>
      <Grid container spacing={4} className="grid-container">
        {cardData.map((card, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} container>
            <Card 
              className={`card ${visibleCards[index]}`}
              onClick={() => {
                if (card.transitionFlag) {
                  window.open(card.link, '_blank');
                } else {
                  navigate(card.link);
                }
              }}
              style={{ position: 'relative' }}
              ref={el => cardRefs.current[index] = el}
            >
              <CardMedia
                component="img"
                image={card.imageUrl}
                alt={card.title}
              />
              <CardContent>
                <Typography variant="h5" component="div" fontWeight="bold">
                  {card.title}
                </Typography>
                <Typography variant="body2" color="text.secondary" style={{ minHeight: '60px' }}>
                  {card.description}
                </Typography>
                {card.transitionFlag ? ( 
                  <IconButton 
                    className="icon-animation"
                    style={{ position: 'absolute', bottom: '8px', right: '8px' }} 
                  >
                    <LaunchIcon />
                  </IconButton>
                ): (
                  <IconButton
                    className="icon-animation"
                    style={{ position: 'absolute', bottom: '8px', right: '8px' }} 
                  >
                    <ArrowForwardIcon />
                  </IconButton>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <div className="title-container">
        <h1 className="overlay-text"><br/>Declaration</h1>
        <h2 className="title">来世は技術ブログになる。</h2>
      </div>
      <div style={{ textAlign: 'center', margin: '20px 0' }}>
        <IconButton 
          onClick={() => window.open('https://github.com/zukaseka', '_blank')}
          style={{ 
            padding: 0,
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.7)',
          }}
        >
          <img 
            src={GitHubIcon} 
            alt="GitHub"
            style={{ 
              width: '80px',
              height: '80px',
              filter: 'invert(100%)'
            }}
          />
        </IconButton>
      </div>
    </>
  );
};

export default Home;